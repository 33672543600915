import React from 'react';
import AudioPlayer from './AudioPlayer';
import Transcript from './layout/Transcript';
import Gallery from './layout/Gallery';


const BottomWidget = (props) => {

const {activePointData, widgetRef,audioPlayStatus, setAudioPlayStatus, mapTop} = props;

    return <div id="widget" ref={widgetRef}>
            {activePointData?.audio &&
                    <AudioPlayer audioPlayStatus={audioPlayStatus} url={activePointData.audio.url}/>}
            {activePointData?.text &&
                    <Transcript mapTop={mapTop} setAudioPlayStatus={setAudioPlayStatus} transcript={activePointData.text}/>}
            {activePointData?.gallery?.length > 0 &&
                    <Gallery mapTop={mapTop} setAudioPlayStatus={setAudioPlayStatus} gallery={activePointData.gallery}/>}
        </div>;
};


export default BottomWidget;