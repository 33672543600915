import React, {useState, useRef, useEffect, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import arrowDark from '../../images/arrow-dark.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, EffectFade} from 'swiper';
import leftArrow from '../../images/leftSliderArrow.svg';
import rightArrow from '../../images/rightSliderArrow.svg';


import 'swiper/css';
import 'swiper/css/effect-fade';
import {Settings} from '../../store';

const Gallery = (props) => {
    const [showModal, setShowModal] = useState(false);
    const {gallery, setAudioPlayStatus, mapTop} = props;
    const [swiper, setSwiper] = useState();
    const prevRef = useRef();
    const nextRef = useRef();
    const [activeCaption, setActiveCaption] = useState('');  // State to store active caption
    const [expanded, setExpanded] = useState(false);


    const dispatch = useContext(Settings.Dispatch);

    const hideGalleryModal = () => {
        setShowModal(false);
        dispatch({type: 'setHideTopWidget', payload: false});
    };
    const showGalleryModal = () => {
        setShowModal(true);
        dispatch({type: 'setHideTopWidget', payload: true});
    };

    useEffect(() => {
        if (swiper && swiper.params) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();

            // Set initial caption for the first slide
            if (gallery && gallery.length > 0) {
                setActiveCaption(gallery[0].caption);  // Set first caption initially
            }
        }
    }, [swiper, gallery]);
    const handleSlideChange = (swiper) => {
        const activeIndex = swiper.activeIndex;
        setActiveCaption(gallery[activeIndex]?.caption || '');  // Update the caption based on the active slide
    };
    return (<>
        {!showModal &&
                <Button className="gallery-button" onClick={showGalleryModal}><span>Gallery</span><img src={arrowDark}
                                                                                                       alt=""/></Button>}
        <Modal size="xl"
               style={{top: mapTop}}
               fullscreen={false}
               scrollable={false}
               id={'gallery-modal'}
               dialogClassName={expanded ? 'd-flex m-0  align-items-end expanded' : 'd-flex m-0  align-items-end'}
               aria-labelledby="contained-modal-title-vcenter"
               className={'widget-modal'}
               backdrop={false}
               show={showModal} onHide={hideGalleryModal}

        > <Modal.Header className="p-3" closeButton>
            <span style={{display: expanded ? 'inline-block' : 'none'}} onClick={()=> {
                setExpanded(!expanded)
            }}><svg xmlns="http://www.w3.org/2000/svg" width="23.414" height="24.813" viewBox="0 0 23.414 24.813">
  <g id="Group_141" data-name="Group 141" transform="translate(-28.551 -118.375)">
    <path id="Path_116" data-name="Path 116" d="M11.714,0V2.271h5.507L9.691,10.211l1.515,1.6,7.539-7.938V9.682H20.9V0Z"
          transform="translate(18.859 131.374)" fill="#001233"/>
    <path id="Path_117" data-name="Path 117" d="M11.714,0V2.271h5.507L9.691,10.211l1.515,1.6,7.539-7.938V9.682H20.9V0Z"
          transform="translate(61.656 130.188) rotate(180)" fill="#001233"/>
  </g>
</svg>
</span>
            <span onClick={()=> {
                setExpanded(!expanded)
            }} style={{display: expanded ? 'none' : 'inline-block'}}><svg xmlns="http://www.w3.org/2000/svg" width="20.899" height="22.027" viewBox="0 0 20.899 22.027">
  <path id="Path_111" data-name="Path 111"
        d="M11.714,0V2.271h5.507L9.691,10.211,2.155,18.148v-5.8H0v9.681H9.186V19.754H3.675l7.531-7.94,7.539-7.938V9.682H20.9V0Z"
        transform="translate(0 0)" fill="#001233"/>
</svg>
</span>
        </Modal.Header>
            <Modal.Body className="text-center d-flex flex-column">
                <Swiper
                        modules={[Navigation, EffectFade]}
                        effect="fade"
                        fadeEffect={{crossFade: true}}
                        navigation
                        onSwiper={setSwiper}
                        onSlideChange={handleSlideChange}
                >
                    {gallery && gallery.map((item) => {
                        return <SwiperSlide key={item.id}><img className="img-fluid" src={item.url} key={item.id}
                                                               alt="gallery"/></SwiperSlide>;
                    })}

                </Swiper>
                <div className="slider-navigation d-flex align-items-center justify-content-between gap-2 pt-3">
                    <span ref={prevRef} className="custom-prev">
                        <img src={leftArrow} alt="leftArrow"/>
                    </span>
                    <div id="image-caption">{activeCaption}</div>
                    <span ref={nextRef} className="custom-next">
                        <img src={rightArrow} alt="rightArrow"/>
                    </span>
                </div>
            </Modal.Body>
        </Modal>
    </>);
};
export default Gallery;