import React from 'react';
import arrow from '../images/arrow-right.png';

const RouteCard = (props) => {

    const {image, title, text, url, stops, distance, status} = props;

    return <>
        <div className="route-card position-relative">
            {!status &&
                    <span className="coming-soon position-absolute top-0 end-0 px-3 py-2 fs-26 ba-font">Coming soon!</span>}
            <img src={image} alt={title}/>
            <div className="p-4 content-inside">

                <h2 className="mb-2">{title}</h2>
                <p className="fs-15">{text}</p>
                <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
                    {status && <a href={url} className="button"><span>Let’s explore</span> <img src={arrow}
                                                                                                alt="icon"/></a>}
                    <div className="d-flex align-items-center justify-content-between gap-1 fs-14">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18.861"
                             height="28.496" viewBox="0 0 18.861 28.496">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_11" data-name="Rectangle 11" width="18.861" height="28.496"
                                          transform="translate(0 0.001)" fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Location_Pin" data-name="Location Pin" transform="translate(0 0)">
                                <g id="Group_7" data-name="Group 7" transform="translate(0 -0.001)"
                                   clipPath="url(#clip-path)">
                                    <path id="Path_5" data-name="Path 5"
                                          d="M18.858,9.459A9.429,9.429,0,1,0,0,9.429c-.06,5.7,2.894,9.907,6.027,14.324,1.044,1.432,2.088,2.954,3.072,4.565a.344.344,0,0,0,.328.179.413.413,0,0,0,.328-.179c.984-1.582,2.059-3.1,3.1-4.6,3.1-4.416,6.117-8.593,6-14.263m-3.6.936C13.694,10.4,10,12.346,10,15.421H8.864c0-3.075-3.7-5.026-5.257-5.026V9.262c1.56,0,5.257-1.951,5.257-5.026H10c0,3.075,3.7,5.026,5.257,5.026Z"
                                          fill="#001233"/>
                                </g>
                            </g>
                        </svg>
                        <span className=" fs-14">{stops} stops</span>
                        {distance && <span>|</span>}
                        <span className="fs-14">{distance}</span>
                    </div>
                </div>
            </div>
        </div>
    </>;
};


export default RouteCard;