import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import arrow from '../images/arrow-right.png';
import exploreImage from '../images/explore.jpg';
import shape from '../images/shape-explore.png';
const LetsExplore = () => {
    const [showModal, setShowModal] = useState(false);

    const hideExploreModal = () => {
        setShowModal(false);
    };
    const showExploreModal = () => {
        setShowModal(true);
    };



    return (<>
        <a className={'explore-button'} onClick={showExploreModal}><span>Explore</span><img
                src={arrow}
                alt="Explore"/></a>
        <Modal size="xl"
               fullscreen={false}
               scrollable={false}
               animation={false}
               backdrop="static"
               dialogClassName={'d-flex m-0 align-items-start'}
               aria-labelledby="contained-modal-title-vcenter"
               className={'explore-modal p-3'}
               centered show={showModal} onHide={hideExploreModal}
        >
            <Modal.Body className="p-0">
                <img className="img-fluid" src={exploreImage} alt="explore"/>
                <div className="p-3 pb-5 position-relative" style={{background: '#EF916C url(' + shape + ') bottom -20px right -40px',backgroundRepeat:'no-repeat'}}>
                    <h1 className="pt-2 pb-3">Get ready to
                        discover untold
                        LGBTQIA+ stories</h1>
                    <p className=" pb-3">ListenQueer is a personal tour guide through the rich LGBTQIA+ history of
                        several cities,
                        bringing to
                        life
                        forgotten people. Designed to be accessible, the app can be used on site, as a walking tour
                        guide,
                        or to
                        explore queer history from anywhere in the world.
                        <br/><br/>
                        ListenQueer takes users in the footsteps of our queer, trans and nonbinary forebears, mapping
                        their
                        paths
                        onto present-day spaces. Users can select historical figures based on proximity or follow a
                        guided
                        walk
                        listening to the audio descriptions and following directions provided in the app. ListenQueer
                        provides an
                        immersive experience to listeners at home or live on the street.
                    </p>
                    <a href={'/locations/5/'} className={'button big mb-5'}><span>Let’s explore</span> <img src={arrow}
                                                                                alt="icon"/></a>

                </div>
            </Modal.Body>
        </Modal>
    </>);
};
export default LetsExplore;