import React from 'react';
import arrow from '../images/arrow.svg';
import Modal from 'react-bootstrap/Modal';


const FinalScreen = (props) => {

    const {setFinalModal, finalModal, startTourAgain} = props;

    const hideFinalModal = () => {
        setFinalModal(false);
    };
    return <>
        <Modal size="sm"
               fullscreen={true}
               id={'final-modal'}
               scrollable={false}
               dialogClassName={'d-flex align-items-center m-0'}
               aria-labelledby="contained-modal-title-vcenter"
               centered
               backdrop={true}
               className={'p-3 m-0 final-modal'}
               show={finalModal} onHide={hideFinalModal}
        >
            <Modal.Body className={'p-0'}>
                <div className="p-4">
                    <p>You have now reached the end of the tour. Thank you for joining us!
                        <br/><br/>
                        Where to next? </p>
                    <a className="button d-inline-flex align-items-center justify-content-center gap-3"
                       onClick={(e) => {
                           e.preventDefault();
                           startTourAgain();
                       }}><span>Start the tour again!</span><img style={{width: 40, height: 'auto'}} src={arrow}
                                                                 alt="arrowLeft"/></a>
                    <a href={'/locations/5/'}
                       className="button d-inline-flex align-items-center justify-content-center gap-3"><span>Explore other tours</span><img
                            style={{width: 40, height: 'auto'}} src={arrow}
                            alt="arrowLeft"/></a>
                </div>

            </Modal.Body>
        </Modal>

    </>;
};


export default FinalScreen;