import React, {useState} from 'react';
import logo from '../images/lq-logo.svg';
import arrowIcon from '../images/ArrowIcon.svg';
import LetsExplore from './LetsExplore';
import Disclaimer from './Disclaimer';
import Locations from './Locations';


const Home = () => {

    const [disclaimerModal, setDisclaimerModal] = useState(false);



    return <>
        <img src={logo} alt="logo"/>
        <p>Accessible audio-based queer history</p>
        <LetsExplore/>
    </>;
};


export default Home;