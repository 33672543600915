import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import arrow from '../images/arrow-right.png';
import exploreImage from '../images/explore.jpg';
import shape from '../images/shape-explore.png';

const Disclaimer = () => {

    const [disclaimerModal, setDisclaimerModal] = useState(true);

    return (<>
        <Modal size="xl"
               fullscreen={false}
               scrollable={false}
               animation={false}
               backdrop="static"
               dialogClassName={'d-flex m-0 align-items-start'}
               aria-labelledby="contained-modal-title-vcenter"
               className={'explore-modal p-3'}
               centered show={disclaimerModal} onHide={() => {
            setDisclaimerModal(false);
        }}
        >
            <Modal.Body className="p-0">
                <img className="img-fluid" src={exploreImage} alt="explore"/>
                <div className="p-3 pb-5 position-relative" style={{
                    background: '#EF916C url(' + shape + ') bottom -20px right -40px',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <h1 className="pt-2 pb-3">Disclaimer</h1>
                    <p className=" pb-3">Please note that ListenQueer explores different facets of gender and sexuality
                        and contains explicit mentions
                        of sex. We also discuss queer bodies and, occasionally, violence. This content may not be
                        suitable for
                        people under 18 years of age. Please click ‘I confirm’
                        to confirm you have read this information and would like to continue to the app.
                    </p>
                    <a onClick={() => {
                        setDisclaimerModal(false);
                    }} className={'button big mb-5'}>I confirm <img src={arrow}
                                                                    alt="icon"/></a>

                </div>
            </Modal.Body>
        </Modal>
    </>);
};
export default Disclaimer;