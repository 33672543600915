import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import arrowDark from '../images/arrow-dark.svg';
import playButton from '../images/playButton.svg';
import rewind from '../images/rewind.svg';
import forward from '../images/forward.svg';

const AudioPlayer = (props) => {
    const {url, audioPlayStatus} = props;

    const playerRef = useRef(null);
    const [playing, setPlaying] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [seeking, setSeeking] = useState(false);


    const [showModal, setShowModal] = useState(false);
    const hideAudioModal = () => {
        setShowModal(false);
    };
    const showAudioModal = () => {
        setShowModal(true);
    };

    // Format time to mm:ss
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    // Play/Pause toggle
    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    // Stop the audio
    const handleStop = () => {
        setPlaying(false);
        playerRef.current.seekTo(0);
    };

    const handleRewind = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime - 10 > 0 ? currentTime - 10 : 0);
    };

    useEffect(() => {
        setPlaying(audioPlayStatus);
    }, [audioPlayStatus]);

    const handleForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        playerRef.current.seekTo(currentTime + 10 < duration ? currentTime + 10 : duration);
    };

    const handleProgress = (state) => {
        if (!seeking) {
            setCurrentTime(state.playedSeconds);
        }
    };

    const handleDuration = (duration) => {
        setDuration(duration);
    };

    const handleSeekChange = (e) => {
        setCurrentTime(parseFloat(e.target.value));
        playerRef.current.seekTo(parseFloat(e.target.value) / duration);
    };

    const handleProgressClick = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const newTime = (clickX / rect.width) * duration;
        playerRef.current.seekTo(newTime);
        setCurrentTime(newTime);
    };

    const progressPercentage = (currentTime / duration) * 100 || 0;

    return (<>
                {!showModal &&
                        <Button className="audio-button" onClick={showAudioModal}><span>Audio</span><img src={arrowDark}
                                                                                                         alt=""/></Button>}
                <Modal size="sm"
                       fullscreen={false}
                       id={'audio-modal'}
                       scrollable={false}
                       dialogClassName={'d-flex align-items-start m-0'}
                       aria-labelledby="contained-modal-title-vcenter"
                       backdrop={false}
                       className={'p-0 m-0 audio-modal'}
                       show={showModal} onHide={hideAudioModal}
                >
                    <Modal.Header className="p-0" closeButton>
                    </Modal.Header>
                    <Modal.Body className={'p-0'}>

                        <div className="mt-3">
                            <ReactPlayer
                                    bottom={'sd'}
                                    ref={playerRef}
                                    url={url}
                                    playing={playing}
                                    controls={false}
                                    width="0px"
                                    height="0px"
                                    onProgress={handleProgress}
                                    onDuration={handleDuration}
                            />
                            <div
                                    className="progress-bar"
                                    onClick={handleProgressClick}
                            >
                                <div
                                        className="progress-filled"
                                        style={{width: `${progressPercentage}%`}}
                                ><span className="dot"></span></div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1"
                                 style={{fontSize: 12}}>
                                <span>{formatTime(currentTime)}</span>
                                <span>{formatTime(duration)}</span>
                            </div>
                            <div className="controls text-center">

                                {/*<button onClick={handleStop}>Stop</button>*/}
                                <span onClick={handleRewind}><img src={rewind} alt="rewind"/></span>
                                <span style={{margin: '0 16px'}} onClick={handlePlayPause}>{playing ?
                                        <img src={playButton}/> :
                                        <img src={playButton}/>}
                    </span>
                                <span onClick={handleForward}><img src={forward} alt="forward"/></span>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
    )
            ;
};

export default AudioPlayer;
