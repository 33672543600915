import React from 'react';

// Context
const State = React.createContext();
const Dispatch = React.createContext();

//App reducer
const reducer = (state, action) => {
    switch (action.type) {
        case 'setAudioModal':
            return {
                ...state, audioModal: action.payload,
            };
        case 'setGalleryModal':
            return {
                ...state, galleryModal: action.payload,
            };
        case 'setTranscriptModal':
            return {
                ...state, transcriptModal: action.payload,
            };
        case 'setActivePointData':
            return {
                ...state, activePointData: action.payload,
            };
        case 'setActiveRouteName':
            return {
                ...state, activeRouteName: action.payload,
            };
        case 'setTotalStops':
            return {
                ...state, totalStops: action.payload,
            };
        case 'setCurrentStopNumber':
            return {
                ...state, currentStopNumber: action.payload,
            };
        case 'setLocations':
            return {
                ...state, locations: action.payload,
            };
        case 'setLocationRoutes':
            return {
                ...state, locationRoutes: action.payload,
            };
        case 'setHideTopWidget':
            return {
                ...state, hideTopWidget: action.payload,
            };
        default:
            return state;
    }
};

// App provider
const Provider = ({children}) => {
    const defaultState = {
        audioModal: false,
        galleryModal: false,
        transcriptModal: false,
        activePointData: null,
        activeRouteName: '',
        totalStops: [],
        currentStopNumber: null,
        locations :[],
        locationRoutes: [],
        hideTopWidget: false
    };
    const [state, dispatch] = React.useReducer(reducer, {...defaultState});
    return (
            <State.Provider value={state}>
                <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
            </State.Provider>
    );
};

// Export
export const Settings = {
    State, Dispatch, Provider,
};