import React, {useContext} from 'react';
import {Settings} from '../store';
import {detectStopNumber, mapFlyTo, setActivePin} from '../hooks/helpers';

const Stop = (props) => {
    const {stopData, map, setOpenStops, index, lat, lng} = props;
    let dataToStore = {};
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {totalStops} = settingsState;
    const handleClick = (type) => {
        if (type === 'next') {
            dataToStore.gallery = stopData.props.gallery_next;
            dataToStore.text = stopData.props.text_next;
            dataToStore.title = stopData.props.title_next;
            dataToStore.audio = stopData.props.audio_next;
        } else {
            dataToStore = stopData.props;
        }

        dispatch({type: 'setActivePointData', payload: dataToStore});
        let activeMarkerId = null;
        const stopNumber = detectStopNumber(totalStops, dataToStore.title, stopData.lng, stopData.lat).id;
        dispatch({type: 'setCurrentStopNumber', payload: stopNumber});
        setActivePin(map, activeMarkerId, stopData.id);
        mapFlyTo(map, stopData.lng, stopData.lat);
        setOpenStops(false);
    };

    return (<>
                <div className="stop-item" onClick={handleClick}>
                    <svg className="circle-dot" xmlns="http://www.w3.org/2000/svg" width="17.159" height="17.159"
                         viewBox="0 0 17.159 17.159">
                        <defs>
                            <clipPath id="clipPath">
                                <rect id="Rectangle_80" data-name="Rectangle 80" width="17.159" height="17.159"
                                      fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_99" data-name="Group 99" transform="translate(0 0)">
                            <g id="Group_98" data-name="Group 98" transform="translate(0 0)" clipPath="url(#clipPath)">
                                <path id="Path_112" data-name="Path 112"
                                      d="M17.159,8.58A8.58,8.58,0,1,1,8.58,0a8.58,8.58,0,0,1,8.58,8.58"
                                      transform="translate(0 0)" fill="#001233"/>
                            </g>
                        </g>
                    </svg>
                    <span>{index + ' — ' + stopData.props.title}</span></div>
                {stopData.props.title_next !== '' &&
                        <div className="stop-item next" data-next={true} onClick={() => {
                            handleClick('next');
                        }}>
                            <svg id="Right" xmlns="http://www.w3.org/2000/svg" width="11.296" height="18.582"
                                 viewBox="0 0 11.296 18.582">
                                <defs>
                                    <clipPath id="clipPath">
                                        <rect id="Rectangle_66" data-name="Rectangle 66" width="11.296" height="18.582"
                                              fill="none" stroke="#bc88fc" strokeWidth="2"/>
                                    </clipPath>
                                </defs>
                                <g id="Group_84" data-name="Group 84" clipPath="url(#clipPath)">
                                    <path id="Path_102" data-name="Path 102"
                                          d="M1.5,21.582c0-5.6,6.785-9.291,10.113-9.291"
                                          transform="translate(-0.314 -3)" fill="none" stroke="#bc88fc"
                                          strokeLinejoin="bevel" strokeWidth="2"/>
                                    <path id="Path_103" data-name="Path 103" d="M1.5,0c0,5.6,6.785,9.291,10.113,9.291"
                                          transform="translate(-0.314 0)" fill="none" stroke="#bc88fc"
                                          strokeLinejoin="bevel" strokeWidth="2"/>
                                </g>
                            </svg>
                            {stopData.props.title_next}</div>}
            </>
    );

};
export default Stop;