import React from 'react';

const Desktop = () => {

    return (
            <>
                Homepage
            </>
    );

};
export default Desktop;