import React, {useRef, useEffect, useState} from 'react';
import Stop from './Stop';
import dottedLine from '../images/dotted-line.svg';
import {v4 as uuidv4} from 'uuid';

const Stops = (props) => {
    const {mapData, map, mapHeight, openStops, setOpenStops} = props;
    let i = 0;
    let menuContainer = useRef(null);
    const [lineHeight, setLineHeight] = useState(0);

    useEffect(() => {
        if (menuContainer.current) {
            // Create a ResizeObserver to track height changes
            const observer = new ResizeObserver((entries) => {
                if (entries[0].contentRect) {
                    const newHeight = entries[0].contentRect.height;
                    setLineHeight(`${newHeight}px`); // Update lineHeight state
                }
            });

            // Observe the menuContainer for size changes
            observer.observe(menuContainer.current);

            // Clean up observer on component unmount
            return () => {
                if (menuContainer.current) {
                    observer.unobserve(menuContainer.current);
                }
            };
        }
    }, [menuContainer]); // Add ref as dependency


    return <>
        <div style={{height: mapHeight, display: openStops ? 'block' : 'none'}} id="stops" className="overflow-hidden">
            <div className="overflow-y-scroll">
                <div className="dots overflow-hidden"
                     style={{height: lineHeight}}>
                    <img src={dottedLine} alt="dottedLine"/>
                </div>
                <div ref={menuContainer}>{(mapData.length && mapData.map((item, index) => item.properties &&
                        <Stop index={index} setOpenStops={setOpenStops} key={index} map={map} item={item}
                              stopData={item.properties}/>))}</div>
            </div>
        </div>
    </>;

};
export default Stops;