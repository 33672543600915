
export const mapFlyTo = (map, lng, lat) => {

    map && map.flyTo({
        center: [lng, lat],
        speed: 0.8,
        zoom: 19,
    });
}

export const setActivePin = (map,activeMarkerId, id)=> {
    if (activeMarkerId) {
        map.setLayoutProperty('pointMarker', 'icon-image', [
            'case',
            ['==', ['get', 'id'], activeMarkerId],
            'normal-marker',
            'normal-marker',
        ]);

        map.setPaintProperty('pointMarker', 'text-color', [
            'case',
            ['==', ['get', 'id'], activeMarkerId],
            '#001233', // Color for active marker text
            '#001233',  // Default color for other markers
        ]);
    }
    activeMarkerId = id;

    map.setPaintProperty('pointMarker', 'text-color', [
        'case',
        ['==', ['get', 'id'], activeMarkerId],
        '#fff', // Color for active marker text
        '#000',  // Default color for other markers
    ]);

    // Change the icon for the active marker
    map.setLayoutProperty('pointMarker', 'icon-image', [
        'case',
        ['==', ['get', 'id'], activeMarkerId],
        'active-marker',
        'normal-marker',
    ]);
}

export const detectStopNumber = (totalStops, title, lng, lat) => {
    return totalStops.find((item)=> {
        return item.title === title && item.lng === lng && item.lat === lat
    })
}

export const getStopData = (totalStops, title, lng, lat) => {
    return totalStops.find((item)=> {
        return item.title === title && item.lng === lng && item.lat === lat
    })
}
